import { setContext } from '@apollo/client/link/context';

import { sessionService } from '@bluecodecom/digital-goods-webview/features/session';

const authLink = setContext((_, { headers }) => {
  const token = new URLSearchParams(window.location.search).get('claim_token');

  return {
    headers: {
      ...headers,
      'x-claim-token': token,
      Authorization: sessionService.sessionId
        ? `Bearer ${sessionService.sessionId}`
        : undefined,
    },
  };
});

export default authLink;
