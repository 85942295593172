import { ApolloClient, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import cache from './cache';
import { authLink, errorLink, httpLink, wsLink } from './links';

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    ApolloLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    ),
  ]),
  cache,
  connectToDevTools: true,
});

export default client;
