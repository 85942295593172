import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { configService } from '@bluecodecom/digital-goods-webview/features/config';
import { sessionService } from '@bluecodecom/digital-goods-webview/features/session';

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://digital-goods-api.${configService.domain}/client/graphql/ws`,
    connectionParams: () => {
      const token = new URLSearchParams(window.location.search).get(
        'claim_token',
      );

      return {
        'x-claim-token': token,
        'session-id': sessionService.sessionId,
      };
    },
  }),
);

export default wsLink;
