import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // TODO: handle error
      // toast(message, {
      //   type: 'error',
      // });
    });
  }

  if (networkError) {
    console.log({ networkError, operation });
    console.log(`[Network error]: ${networkError}`);
  }
});

export default errorLink;
