import { ApolloLink, createHttpLink } from '@apollo/client';

import { configService } from '@bluecodecom/digital-goods-webview/features/config';

const authHttpLink = createHttpLink({
  uri: `https://digital-goods-api.${configService.domain}/auth/graphql`,
});

const clientHttpLink = createHttpLink({
  uri: `https://digital-goods-api.${configService.domain}/client/graphql`,
});

const publicHttpLink = createHttpLink({
  uri: `https://digital-goods-api.${configService.domain}/public/graphql`,
});

export default ApolloLink.split(
  ({ operationName }) => operationName.startsWith('Public'),
  publicHttpLink,
  ApolloLink.split(
    ({ operationName }) => operationName.startsWith('Auth'),
    authHttpLink,
    clientHttpLink,
  ),
);
