import { DeploymentTargetBcx } from '@bluecodecom/types';

import { environment } from '@bluecodecom/digital-goods-webview/environments/environment';

const DEPLOYMENT_TARGET_ENV = process.env[
  'NX_PUBLIC_DEPLOYMENT_TARGET'
] as DeploymentTargetBcx;

class ConfigService {
  private static instance: ConfigService;

  private _deploymentTarget!: DeploymentTargetBcx;

  constructor() {
    const env = environment.local
      ? 'local'
      : DEPLOYMENT_TARGET_ENV || 'bcx-dev';

    this._deploymentTarget = env;
  }

  public get deploymentTarget() {
    return this._deploymentTarget;
  }

  public get isNotProd() {
    return !['bcx-prod'].includes(this.domain);
  }

  public get domain() {
    // For local development with schema-services, the url will looks like this:
    // https:/digital-goods.your_name.bloomco.de
    // And we need that your_name.bloomco.de
    if (this._deploymentTarget === 'local') {
      const parts = window.location.hostname.split('digital-goods.');

      return parts?.[1] || '';
    }

    switch (this._deploymentTarget) {
      case 'bcx-dev':
        return 'spt-payments.mobi';
      case 'bcx-int':
        return 'spt-payments.biz';
      case 'bcx-prod':
        return 'spt-payments.com';
    }

    throw new Error('Unknown env ' + this._deploymentTarget);
  }

  public static getInstance() {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }

    return ConfigService.instance;
  }
}

const configService = ConfigService.getInstance();

export default configService;
