import { useCallback } from 'react';
import {
  useSearchParams,
  useNavigate as useNavigateBase,
} from 'react-router-dom';

import qs from 'qs';

import { NavigateProps, RoutePath } from './useNavigate.types';

const useNavigate = () => {
  const navigateBase = useNavigateBase();
  const [searchParams] = useSearchParams();

  const currentShowBackButton =
    searchParams.get('showBackButton') === 'true' || false;

  const claimToken = searchParams.get('claim_token');
  const data = searchParams.get('data');

  const navigate = useCallback(
    function <T extends RoutePath>({
      route,
      params,
      replace,
      search,
      state,
      disableBackButton,
    }: NavigateProps<T>) {
      // Build correct path
      const pathname = (() => {
        if (!params) {
          return route;
        }

        return Object.entries(params).reduce(
          (prev: string, [param, value]) =>
            prev.replace(`:${param}`, String(value)),
          route,
        );
      })();

      navigateBase(
        {
          pathname,
          search: qs.stringify({
            showBackButton: disableBackButton
              ? false
              : replace
                ? currentShowBackButton
                : true,
            claim_token: claimToken,
            data,
            ...search,
          }),
        },
        { replace, state },
      );
    },
    [claimToken, currentShowBackButton, data, navigateBase],
  );
  return { navigate };
};

export default useNavigate;
