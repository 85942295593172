class SessionService {
  private static instance: SessionService;

  private _sessionId = '';

  public get sessionId() {
    return this._sessionId;
  }

  public set sessionId(value) {
    this._sessionId = value;
  }

  public static getInstance() {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService();
    }

    return SessionService.instance;
  }
}

const sessionService = SessionService.getInstance();

export default sessionService;
