import { Suspense, lazy, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { nativeService } from '@bluecodecom/common/services/native';
import { ThemeProvider } from '@bluecodecom/common/theme';

import { apolloClient } from '@bluecodecom/digital-goods-webview/features/apollo';

import './i18n';

const App = lazy(() => import('./app'));

const Main = () => {
  useEffect(() => {
    nativeService.allowResumeMiniApp();
    nativeService.hideNavigation();
  }, []);

  return (
    <ThemeProvider observe>
      <LoaderProvider>
        <BrowserRouter>
          <Suspense>
            <ApolloProvider client={apolloClient}>
              <App />
            </ApolloProvider>
          </Suspense>
        </BrowserRouter>
      </LoaderProvider>
    </ThemeProvider>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
