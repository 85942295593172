import { useEffect } from 'react';

import useNavigate, { NavigateProps, RoutePath } from '../../hooks/useNavigate';

const Navigate = <T extends RoutePath>(props: NavigateProps<T>) => {
  const { navigate } = useNavigate();

  useEffect(() => {
    navigate(props);
  }, [navigate, props]);

  return null;
};

export default Navigate;
