import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { NavigationComponents } from '@bluecodecom/digital-goods-webview/features/navigation';

import { sessionService } from '../../service';
import { AUTH_SESSION_QUERY, AuthSessionQueryResult } from './gql';

const Ensure: FC<PropsWithChildren> = ({ children }) => {
  const apolloClient = useApolloClient();
  const [searchParams] = useSearchParams();

  const [sessionId, setSessionId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const paramSessionId = useMemo(
    () => searchParams.get('sessionId'),
    [searchParams],
  );

  useEffect(() => {
    if (paramSessionId) {
      sessionService.sessionId = paramSessionId;

      setSessionId(paramSessionId);
      setIsLoading(false);

      return;
    }

    apolloClient
      .query<AuthSessionQueryResult>({
        query: AUTH_SESSION_QUERY,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (!data.session) {
          return;
        }

        sessionService.sessionId = data.session;
        setSessionId(data.session);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apolloClient, paramSessionId]);

  if (isLoading) {
    return null;
  }

  if (!sessionId) {
    return <NavigationComponents.Navigate route="/error" />;
  }

  return children;
};

export default Ensure;
